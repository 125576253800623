// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular.json`.

export const environment = {
    production: false,
    // apiUrl: 'https://api.fxconnectus.org' // For Production
    assetsUrl: 'https://fx-static-dev.s3.amazonaws.com/',
    apiUrl: 'https://fxqaapi.brainvire.net',// For development
    cometChat: {
        apiKey: '2d7a2da62b1ab8f6f7937f1a5de895a29ec2ef7b',
        appId: '2275602fbb6fe60e',
        appRegion: 'us'
    }
};
