import { CeiboShare } from 'ng2-social-share';
import { SocialShareComponent } from './../_component/social-share/social-share.component';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { CommonPipe } from './common.pipe';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { UploadFile } from 'ng-zorro-antd/upload';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';

import {
  NzGridModule,
  NzIconModule,
  NzBreadCrumbModule,
  NzListModule,  
  NzFormModule,
  NzCheckboxModule,
  NzPopoverModule,
  NzStatisticModule,
  NzCardModule,
  NzCascaderModule,
  NzTagModule,
  NzSwitchModule,
  NzSkeletonModule,
  NzTabsModule,
  NzUploadModule,
  NzInputNumberModule,
  NzModalModule,
  NzAvatarModule,
  NzButtonModule,
  NzInputModule,
  NzDropDownModule,
  NzPageHeaderModule,
  NzToolTipModule,
  NzTableModule,
  NzSpinModule,
  NzPopconfirmModule,
  NzSelectModule,
  NzDatePickerModule,
  NzDividerModule,
  NzBadgeModule,
  NzDescriptionsModule,
  NzNotificationModule,
  NzRadioModule,
  NzStepsModule,
  NzPaginationModule,
  NzCarouselModule
} from 'ng-zorro-antd';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { FilterComponent } from './components/filter/filter.component';
import { NgxMaskModule } from 'ngx-mask';
import { ImageCropperModule } from 'ngx-image-cropper';
import { TruncatePipe } from '../_common/format-text.pipe';
import { ParseURLPipe } from './parse-url.pipe';

import { DynamicContentDirective } from './dynamic-content.directive';



const BASE_MODULES = [CommonModule, FormsModule, ReactiveFormsModule, ScrollingModule,PdfViewerModule];

const NZ_MODULES = [
  NzGridModule,
  NzIconModule,
  NzBreadCrumbModule,
  NzListModule,
  NzFormModule,
  NzCheckboxModule,
  NzPopoverModule,
  NzStatisticModule,
  NzCardModule,
  NzCascaderModule,
  NzTagModule,
  NzSwitchModule,
  NzSkeletonModule,
  NzTabsModule,
  NzUploadModule,
  NzInputNumberModule,
  NzModalModule,
  NzAvatarModule,
  NzDropDownModule,
  NzInputModule,
  NzToolTipModule,
  NzTableModule,
  NzSpinModule,
  NzPopconfirmModule,
  NzSelectModule,
  NzDatePickerModule,
  NzDividerModule,
  NzBadgeModule,
  NzPageHeaderModule,
  NzDescriptionsModule,
  NzButtonModule,
  NzNotificationModule,
  NzRadioModule,
  NzAutocompleteModule,
  NzCollapseModule,
  NzStepsModule,
  NzDatePickerModule,
  NzPaginationModule,
  NgxMaskModule,
  ImageCropperModule,
  PdfJsViewerModule,
NzCarouselModule
  
];


const SHARED_COMPONENTS = [
  SidebarComponent,
  FilterComponent,
  SocialShareComponent,
  CeiboShare
];

const PIPES = [
  CommonPipe, TruncatePipe,ParseURLPipe
];

const DIRECTIVES = [
    DynamicContentDirective
];

@NgModule({
  declarations: [...PIPES, ...DIRECTIVES, ...SHARED_COMPONENTS],
  imports: [...BASE_MODULES, ...NZ_MODULES],
  exports: [...BASE_MODULES, ...NZ_MODULES, ...PIPES, ...DIRECTIVES, ...SHARED_COMPONENTS],
  // entryComponents: [...ENTRY_COMPONENTS],
})

export class SharedModule { }
